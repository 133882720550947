.ft4{
    top: 100%;
    position: absolute;
    width: 100%;
}

.na
{
    position: absolute;
}

.search-bar-container {
    padding-top: 20vh;
    width: 40%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 200px;
  }

.dat{
    top: 30%;
    left: -17%;
    position: absolute;
    width: 100%;
    height: auto;
}