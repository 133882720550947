.NavbarItems {
  background-color: #ffffff;
  display: flex;
  text-decoration: none;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  box-shadow: 0 5px 15px 0 #333333;
  width: 95%;
  height: 80px;
  border-radius: 10px;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translate(-50%);
  z-index: 9999;
}

.navbar-logo {
  color: #222222;
  font-size: 2rem;
  cursor: pointer;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  align-items: center;
}

.nav-links {
  text-decoration: none;
  color: #222222;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0.7rem 1rem;
  white-space: nowrap;
}
.nav-links i {
  padding-right: 10px;
}
.nav-links:hover {
  background-color: aquamarine;
  color: white;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.nav-links-mobile {
  display: none;
}

button {
  background-color: #888888;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  border-radius: 3px;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

button:hover {
  background-color: #333333;
  color: wheat;
}

.menu-icons {
  display: none;
}

@media screen and (max-width: 850px) {
  .NavbarItems {
    z-index: 99;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #ffffff;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: -100%;
    opacity: 0;
    align-items: stretch;
    padding: 80px 0 30px 0;
    margin: 0;
    transition: 0.3s ease-in-out;
    border-radius: 13px;
  }

  .nav-menu.active {
    left: 0;
    opacity: 1;
    z-index: -1;
    transition: 0.3s ease-in-out;
  }

  .nav-links {
    display: block;
    width: 100%;
    font-size: 1.2rem;
    padding: 2rem 0;
  }
  .nav-links:hover {
    background: #01959a;
    transition: none;
  }
  button {
    display: none;
  }
  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: auto;
    border-radius: 4px;
    width: 80%;
    background: #01959a;
    text-decoration: none;
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 2px;
  }
  .menu-icons {
    display: block;
    cursor: pointer;
  }
  .menu-icons i {
    font-size: 1.2rem;
    color: #222222;
  }
}
